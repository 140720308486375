#Nav2_0.kh5tk5evuhm-editor_css {
  position: sticky;
  background-color: rgb(240, 65, 52);
}
#Feature0_0 .home-page > div > .kh60m4918lr-editor_css {
  justify-content: space-around;
}
#Content11_0.kh5zjzkhroo-editor_css {
  height: 600px;
  background-image: url();
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh7esaa78yq-editor_css {
  background-image: url('/src/images/banner4.jpg');
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh7esi272xo-editor_css {
  background-image: url('/src/images/banner1.jpg');
}
#Banner0_0.kh629otgpxq-editor_css {
  height: 480px;
  background-image: url('/src/images/banner5.jpg');
}
#Banner0_0 .kh7evnq2kro-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh7evwsinwm-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh7ew83vks-editor_css {
  opacity: 0;
}
