#Banner1_0 .banner-anim > .banner-anim-elem > .kh78eogdbp-editor_css {
  background-image: url('/src/images/banner5.jpg');
}
#Feature0_1 .home-page > div > .kh79vl6v4ne-editor_css {
  justify-content: center;
}
#Feature0_1 .ant-col > .content0-block-item > .kh79vo3ws99-editor_css {
  width: 200px;
  height: 200px;
}
#Feature0_1.kh7afz71jzi-editor_css {
  min-height: 500px;
}
.feature8-block-child {
  padding: 24px 8px;
}
#Feature0_0 .ant-row {
  justify-content: center;
}