#Teams1_0 .ant-row > .ant-col > .kh76kdtpt1q-editor_css {
  width: 160px;
}
#Teams1_0 .ant-row > .ant-col > .kh76l8xkc3p-editor_css {
  width: 160px;
}
#Teams1_0 .ant-row > .ant-col > .kh76lc5s18m-editor_css {
  width: 160px;
}
#Teams1_0 .ant-row > .ant-col > .kh76leo6x8c-editor_css {
  width: 160px;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh76p7oa5o-editor_css {
  background-image: url('/src/images/banner1.jpg');
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kh76qgqhair-editor_css {
  background-image: url('/src/images/banner2.jpg');
}
#Teams1_1 .ant-row > .ant-col > .kh7731dwdin-editor_css {
  width: 160px;
}
#Teams1_1 .ant-row > .ant-col > .kh773btka8b-editor_css {
  width: 160px;
}
#Teams1_1 .ant-row > .ant-col > .kh773dwvfvf-editor_css {
  width: 160px;
}
#Teams1_1 .ant-row > .ant-col > .kh773fz8t1q-editor_css {
  width: 160px;
}
#Teams1_2 .ant-row > .ant-col > .kh77iynsns-editor_css {
  width: 160px;
}
#Teams1_2 .ant-row > .ant-col > .kh77j4d0s7d-editor_css {
  width: 160px;
}
#Teams1_2 .ant-row > .ant-col > .kh77j6gdan-editor_css {
  width: 160px;
}
#Teams1_2 .ant-row > .ant-col > .kh77j8ds9f7-editor_css {
  width: 160px;
}
#Teams1_2 .ant-row > .ant-col > .kh77jaatksm-editor_css {
  width: 160px;
}
#Teams1_2 .home-page > div > .kh785klpbl-editor_css {
  justify-content: center;
}
#Teams1_1 .home-page > div > .kh77jqdaoif-editor_css {
  justify-content: center;
}
#Teams1_0 .home-page > div > .kh76jqdaois-editor_css {
  justify-content: center;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhfk5n3tyn-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhfm4lis1-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhfmbbyci-editor_css {
  opacity: 0;
}
