#Feature3_0.kh74zcr3gf-editor_css {
  min-height: 600px;
}
#Banner0_0 .banner0-text-wrapper > .khhgb0yki96-editor_css {
  opacity: 0.02;
}
#Banner0_0 .khhgaw8ispp-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhgce8abct-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhgcvu3wip-editor_css {
  background-image: url('/src/images/banner6.jpg');
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhgd4bgq3-editor_css {
  opacity: 0;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .khhgdavq2qq-editor_css {
  background-image: url('/src/images/banner3.jpg');
}
#Banner0_0.khhgbdw5qo-editor_css {
  background-image: url('/src/images/banner4.jpg');
}
