body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Content1_0.kh7anlw1ci-editor_css {
  height: 700px;
}
#Feature1_0.kh7arwzj8bn-editor_css {
  height: 800px;
}
#Banner0_0.kh7asfj3w37-editor_css {
  height: 640px;
  background-image: url(http://pmo96006d.pic42.websiteonline.cn/upload/6gxq.jpg);
}
#Content13_0.kh7atzaupxq-editor_css {
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
}
